import React from 'react';
import { AutocompleteInput, BooleanInput, ReferenceInput } from 'react-admin';
import { makeStyles } from '@mui/styles';
import DragHandle from './DragHandle';
import RemoveButton from './RemoveButton';
import isArray from 'lodash/isArray';
import split from 'lodash/split';

const useStyles = makeStyles({
  moduleNameInput: {
    width: '100%',
  },
  dragHandleContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '.625rem',
  },
  formContainer: {
    flex: 2,
    display: 'block',
    verticalAlign: 'top',
    marginRight: '3.125rem',
  },
  removeContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  removeButton: {
    color: 'red',
    marginTop: '.5rem',
  },
});

const ModuleList = ({ index, removeField, moduleFilter }) => {
  const classes = useStyles();

  // handle case if moduleFilter.publishingStatus passed in as string instead of array
  // where some cases, moduleFilter.publishingStatus appears as "published,published-hidden" string instead of ["published","published-hidden"]
  if (moduleFilter.publishingStatus && !isArray(moduleFilter.publishingStatus)) {
    moduleFilter.publishingStatus = split(moduleFilter.publishingStatus, ',');
  }

  return (
    <>
      <div className={classes.dragHandleContainer}>
        <DragHandle />
      </div>

      <div className={classes.formContainer}>
        <ReferenceInput
          source={`modules[${index}].module`}
          label='Module'
          reference='AdminModule'
          sort={{ field: 'internalName', order: 'ASC' }}
          className={classes.moduleNameInput}
          filter={moduleFilter}
        >
          <AutocompleteInput label='Module' optionText='title' className={classes.moduleNameInput} />
        </ReferenceInput>

        <BooleanInput source={`modules[${index}].includeQuiz`} label='Include quiz' defaultValue={false} />
      </div>

      <div className={classes.removeContainer}>
        <RemoveButton
          handleClick={e => {
            e.preventDefault();
            removeField(index);
          }}
        />
      </div>
    </>
  );
};

export default ModuleList;
