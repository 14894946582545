import React, { useState } from 'react';
import {
  BooleanInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  AutocompleteInput,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PercentageInput from 'components/PercentageInput';
import QuizQuestions from './components/QuizQuestions';
import { validateQuiz } from './index';

const QuizCreate = props => {
  const location = useLocation();
  const quizData = location.state?.quizData || {}; // Fallback to an empty object if no quizData is provided

  // Sanitize the quiz data to remove the id
  const sanitizedQuizData = { ...quizData };
  delete sanitizedQuizData.id;

  // Sanitize questions to remove id, module from each question
  if (sanitizedQuizData.questions) {
    sanitizedQuizData.questions = sanitizedQuizData.questions.map(({ id, module, ...question }) => question);
  }

  // Initialize state for default values
  const [defaultValues] = useState(sanitizedQuizData);

  return (
      <Create title='Create a Quiz' {...props}>
        <TabbedForm validate={validateQuiz} defaultValues={defaultValues}>
          <FormTab label='Quiz Options'>
            <Helmet>
              <title>{props?.options?.label}</title>
            </Helmet>
            <ReferenceInput
                label='Module'
                name='module'
                source='module'
                reference='AdminModule'
                sort={{ field: 'internalName', order: 'ASC' }}
            >
              <AutocompleteInput optionText={module => `${module.internalName} (${module.publishingStatus})`} />
            </ReferenceInput>
            <NumberInput
                name='numQuestionsToPresent'
                source='numQuestionsToPresent'
                label='Number of Questions Presented to a User'
            />
            <NumberInput
                name='completionTimeInSeconds'
                source='completionTimeInSeconds'
                label='Estimated Minutes to completion'
                format={v => v && v / 60}
                parse={v => v && v * 60}
            />
            <PercentageInput source='passingThresholdPercent' label='Passing Threshold' />
            <BooleanInput name='enabled' source='enabled' />
          </FormTab>

          <FormTab label='Questions'>
            <QuizQuestions />
          </FormTab>
        </TabbedForm>
      </Create>
  );
};

export default QuizCreate;
