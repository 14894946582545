import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  email,
  FormDataConsumer,
  minLength,
  PasswordInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { isUCPAdmin } from 'utils/roles';
import { roleOptions, rolesInitialValue } from '../index';

const validateRequired = [required()];
const validateRequiredText = [required(), minLength(2)];
const validatePassword = [required(), minLength(8)];
const validateEmail = [required(), email()];
const validateRecoveryEmail = [email()];

const BaseUserFieldGroup = () => (
  <>
    <TextInput name='firstName' source='firstName' validate={validateRequiredText} />
    <TextInput name='lastName' source='lastName' validate={validateRequiredText} />
    <TextInput name='email' source='email' type='email' validate={validateEmail} />
    <TextInput name='recoveryEmail' source='recoveryEmail' type='email' validate={validateRecoveryEmail} />

    <ArrayInput name='aliases' source='aliases'>
      <SimpleFormIterator>
        <TextInput label='Email' source='email' />
      </SimpleFormIterator>
    </ArrayInput>

    <BooleanInput label='Opted into Emails' name='emailOpIn.receiveEmails' source='emailOpIn.receiveEmails' defaultValue={true}/>

    <ReferenceInput label='State' name='state' source='state' reference='AdminState' sort={{ field: 'name', order: 'ASC' }}>
      <SelectInput optionText='name' validate={validateRequired} />
    </ReferenceInput>

    <BooleanInput name='isMasaMember' source='isMasaMember' />

    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <ReferenceInput
          key={formData.state}
          label='Agency'
          source='memberAgency'
          reference='AdminAgency'
          filter={{ state: formData.state }}
          sort={{ field: 'name', order: 'ASC' }}
          {...rest}
        >
          <AutocompleteInput optionText='name' validate={validateRequired} />
        </ReferenceInput>
      )}
    </FormDataConsumer>

    <ReferenceArrayInput
      label='Occupations'
      name='occupations'
      source='occupations'
      reference='AdminOccupation'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectArrayInput optionText='name' />
    </ReferenceArrayInput>

    <PasswordInput name='plainPassword' source='plainPassword' validate={validatePassword} />
    <PasswordInput name='plainPasswordConfirm' source='plainPasswordConfirm' validate={validatePassword} />

    {isUCPAdmin() && (
      <BooleanInput name='hasInfiniTEXTAccess' source='hasInfiniTEXTAccess' label='Has InfiniTEXT Access' />
    )}

    {isUCPAdmin() && (
      <SelectArrayInput
        label='Roles'
        name='roles'
        source='roles'
        choices={roleOptions}
        defaultValue={rolesInitialValue}
        validate={required()}
      />
    )}
  </>
);

export default BaseUserFieldGroup;
