import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  NumberField,
  TextField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { QuizFilter } from './index';
import QuizCopyButton from './QuizCopyButton';

const QuizList = props => (
  <List title='Quizzes' {...props} filters={<QuizFilter />} sort={{ field: 'module', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <ReferenceField label='Module Catalog Number' source='module' reference='AdminModule' sortBy='catalogNumber'>
        <TextField source='catalogNumber' />
      </ReferenceField>
      <ReferenceField label='Module Name' source='module' reference='AdminModule' sortBy='internalName'>
        <TextField source='internalName' />
      </ReferenceField>
      <NumberField source='numQuestionsToPresent' label='Number of Questions To Present' />
      <FunctionField label='Total Questions' render={record => record.questions.length} />
      <NumberField source='passingThresholdPercent' label='Passing Threshold' />
      <BooleanField source='enabled' />
      <QuizCopyButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default QuizList;
