import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyQuizButton = () => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const handleCopy = () => {
    // Navigate to the QuizCreate component, passing the record data except module
    navigate('/AdminQuiz/create', {
      state: { quizData: { ...record, module: undefined } },
    });
  };

  return (
    <Button label='Copy' onClick={handleCopy}>
      <ContentCopyIcon />
    </Button>
  );
};

export default CopyQuizButton;
